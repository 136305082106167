export default  {
    system:{
        en:'English',
        id:'Indonesian',
        about:'About',
        product:'Product',
        enter:'Please enter',
        btn:'Demo Admin',
        bnt2:'Demo Client',
        required: "Input cannot be empty",
        tef_ii:'Please provide a valid email address!',
        placeholder:'Type here...',
    },
    home:{
        title1:'Trading Smart Managing Right',
        title:'TradeWise CRM',
        desc1:"TradeWise CRM offers robust data analytics to enhance informed decision-making. It provides flexible marketing strategy configurations, automates KYC processes through facial recognition, and features a built-in trading platform that seamlessly integrates with MT4, MT5 and options trading systems.",
        desc2:'We are dedicated to offering high-quality software solutions to meet the business needs of our customers. As a leading software company, we have built an excellent technical team and a professional customer service team who are able to assist brokers in efficiently managing customers, organizations and employees, thereby achieving rapid business growth and stable expansion.',
        subTitle1:'Built-in Trading System',
        subTitle2:'Mobile App Deployment',
        subTitle3:'Multi-Platform and Multi-Trading Server',
        subTitle4:'Agent Customization with no level limit',
        subTitle5:'Automated KYC with Facial Recognition',
        subTitle6:'Custom Development Services',
        subTitle7:'We offer customized features designed to meet your unique requirements. Our bespoke development services cater to a variety of needs, supporting built-in trading systems and diverse operational activities, among others.',
        title3:"Partners",
        contact:'Contact Us',
        is_out_in:"Request More Information", // Request More Information
        TM:'All rights reserved.© 2023 TradeWise Software PTE. Limited. A registered company in Singapore.',
        sys_name:'TradeWise CRM',
        subTitle9:'Solutions for Brokers',
        subTitle10:'Efficient Way to Improve Your Management and Boost YourProductivity',
        title4:'Multi-Platform & Multi-Trading Server',
        desc:'Support multiple trading platforms, including MT4, MT5, and Options, providing a unified view.',
        desc3:'Support unified management of client funds across different trading platforms and servers, simplifying the deposit and withdrawal process.',
        desc4:'Enable users to open and manage multiple trading accounts on different trading servers under the same user profile.',
        desc5:'Flexible account type settings, such as Standard, ECN,and STP accounts.',
        title5:'Automated KYC With Facial Recognition',
        desc6:'Configurable KYC requirements based on different countries and regions, featuring real-time facial recognition and verification.',
        subTitle11:'Global Compliance Document Management',
        desc7:'Flexibly configure the required documentation based on compliance requirements of different countries. Support OCR-based automatic recognition of various identity documents, including ID cards, passports, driving licenses, etc., ensuring accuracy and efficiency in document processing.',
        subTitle12:'Automated KYC Process Optimization',
        desc8:'Utilize real-time facial recognition and verification to enhance the efficiency of customer identity validation. This feature accelerates the review process, reduces customer attrition, and effectively lowers labor costs.',
        // title6:'Agent Customization with no level limit',
        desc9:'Create different commission structures for all agents to improve your referral program.',
        subTitle13:'Customizable Commission Structures',
        subTitle14:'Cross-Platform Commission Tracking and Management',
        subTitle15:'Multi-Tier Reward Mechanism',
        subTitle16:'Flexible Commission Payment Options',
        desc10:'Easily adjust commission structures on-demand to align incentives program with your company`s goals, enhancing agent engagement and motivation.',
        desc11:'Centralize the tracking and management of commissions across multiple trading platforms, providing a comprehensive overview to help administrators efficiently monitor performance and optimize agent engagement.',
        desc12:'Design flexible multi-tier reward systems that align brokers’ different operational strategies, ensuring that incentive programs are responsive to market dynamics and drive agent productivity.',
        desc13:'Offer a variety of commission payment methods, including single transactions, periodic payments, and performance-based dynamic payments, to meet the diverse needs of agents and enhance payment flexibility.',
        // title7:'Mobile App Deployment',
        desc14:'Packaged as a mobile app for customer acquisition through channels such as Google and Facebook. Brokers can utilize app stores, social media, and search engines to obtain downloads precisely and with low cost per download, transitioning from traditional offline customer acquisition methods to internet-based brokerage.',
        tel:'Tel',
        email:'Email',
        addr:'Address',
        name:'Your Name',
        reci:'recipient',
        msg:'Your Message',
        your_email:'Your Email',
        sendMsg:'Send'
    }
}